<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Nome:</b> {{ employee.name }}
      </getecma-p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GetecmaEmployeeViewInformation',
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
};
</script>
